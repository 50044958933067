html, body {
    margin: 0;
    padding: 0;
    overflow-x: hidden; /* Prevent horizontal scrolling */
    width: 100vw;

  }
  
/* Phones (max-width: 600px) */
@media only screen and (max-width: 600px) {
  /* General font size adjustment */
  html {
    font-size: 12px; /* Scale down font size for smaller screens */
  }
  /* Navbar */
  .navbar {
    /* flex-direction: column; */
    font-size: 2rem;
    padding: 10px;
    align-items: center;
  }
  .navbar-right.active {
    display: flex;
    height: 50vh;
  }
  .aboutme-icons {
    grid-template-columns: 1fr; /* Stack items on small screens */
  }

  .icon-info {
    font-size: 1.5rem;
    text-align: center;
    justify-content: left;
  }
  .navbar-right {
    display: none;
    flex-direction: column;
    position: absolute;
    justify-content: space-evenly;
    top: 100%;
    right: 0;
    background-color: var(--background);
    width: 100vw;
    margin: 0;
    text-align: center;
    padding: 1rem;
    z-index: 99999999;
  }

  .navbar-right a {
    display: block;
    padding: 1rem 0;
    font-size: 1.8rem;
  }

  .navbar-right.active {
    display: flex;
  }

  .main-div {
    height: 90vh;
  }
  h1 {
    font-size: 2rem;
  }
  .img-div {
    height: 40%;
  }
  /* project section */
  .web-project {
    display: flex;
    flex-direction: column;
  }
  .creative-project {
    display: flex;
    flex-direction: column;
  }

  /* Main Heading */
  .main-heading-title {
    font-size: 3.5rem;
    /* text-align: center; */
    margin: 1rem;
  }
  .about-me {
    width: 80%;
    text-align: center;
    font-size: 1.3rem;
    margin: auto;
    margin-top: 2rem;
  }
  /* Hero Section */
  .img-herobanner {
    height: auto;
    margin-top: 5rem;
    max-width: 100%;
  }

  /* Projects Section */
  /* .projects-web,
  .projects-creative {
    padding: 0 10px;
    width: 100%;
  }

  .web-flex,
  .creative-flex {
    flex-direction: column;
    gap: 2rem;
  }

  .web-project,
  .creative-project {
    flex: 1 1 100%;
    margin: 0 auto;
  } */

  /* Images */
  /* .project-img {
    max-width: 100%;
    height: auto;
  } */

  /* Hide elements */
  .card {
    display: none;
  }
  h2 {
    font-size: 3rem;
  }
  #tech {
    display: none;
  }

  .tech {
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
  .tech-mobile {
    display: flex;
    justify-content: space-between;
    gap: 2rem;
    margin-bottom: 2rem;
    margin-top: 4rem;
    max-width: 80vw;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
  .project-img-mobile {
    display: block;
    width: 50vw;
    border: #fcfcfd 2px solid;
    padding: 20px;
    margin: auto;
    margin-bottom: 5rem;
    box-shadow: rgba(217, 164, 161, 0.8) 0px 14px 28px,
      rgba(217, 164, 161, 0.5) 0px 10px 10px;
  }
  .project-img {
    width: 50vw;
  }
  #project-img {
    display: none;
  }
  .project-desc {
    font-size: 1.3rem;
    text-align: center;
    width: 80vw;
    margin: auto;
  }

  .project-heading {
    font-size: 13rem;
  }
  .project-content {
    padding: 0;
  }
  .project-heading-inside {
    font-size: 2.5rem;
    text-align: center;
  }

  /* Navigation Arrows */
  .arrow-icon {
    font-size: 2rem;
  }
  .project-heading {
    margin: 0;
    text-shadow: 0.008em 0.003em 0 rgb(28, 203, 212, 1);
  }
  /* Buttons */
  .button-30 {
    font-size: 1.2rem;
    padding: 5px 15px;
  }
  .project-header {
    margin-top: 5rem;
    width: 90vw;
  }
  .navbar {
    padding: 1rem;
  }

  .icon-font {
    display: block;
    margin-right: 2rem;
  }
  .contact-form {
    width: 70vw;
    height: 100vh;
  }
  .avatar-div {
    display: none;
  }
  hr.gradient {
    height: 3px;
    border: none;
    border-radius: 6px;
    background: linear-gradient(
      90deg,
      rgba(13, 8, 96, 1) 0%,
      rgba(9, 9, 121, 1) 21%,
      rgba(6, 84, 170, 1) 51%,
      rgba(0, 255, 113, 1) 100%
    );
  }
}
cursor {
  z-index: 9999999999999;
}
/* Small Devices (max-width: 480px) */
@media screen and (max-width: 480px) {
  .navbar{
    background-color: yellowgreen;
  }
  .main-heading-title {
    font-size: 4rem;
  }
  .project-heading-inside {
    font-size: 2.2rem;
  }
  .main-div {
    height: 80vh;
  }
  h1 {
    font-size: 1.3rem;
  }
  h2 {
    font-size: 2.5rem;
  }
  .underline {
    width: 68px;
  }
.icon-info{
    font-size: 1rem;
}
  .logo {
    width: 4rem;
  }
  #form_button {
    padding: 15px 25px;
  }
  .navbar {
    /* background-color: yellow; */
    height: 5vh;
    z-index: 99999999;
  }
  .main-heading-title {
    max-width: 50vw;
    /* font-size: 7rem; */
  }

  .img-div {
    height: 30%;
  }
  .container {
    width: 100vw;
  }
  .about-me {
    width: 90vw;
    font-size: 1.5rem;
  }
  .projects-web {
    width: 90vw;
  }
  .project-img {
    padding: 10px;
  }
  .project-content {
    padding: 0;
  }
  .contact-form {
    height: 60vh;
    width: 80vw;
  }
  .form-h2 {
    position: absolute;
    left: 0;
    width: 100%;
  }
  h2 {
    font-size: 2.2rem;
  }
  #container{
    height: 100vh;
  }

  .submit{
    gap: 5rem;
  }
}

/* Extra Small Devices (max-width: 420px) */
@media screen and (max-width: 420px) {
  
  h1 {
    font-size: 18px;
  }
  .project-heading-inside {
    font-size: 2.2rem;
  }
  .icon-info{
    font-size: 1.2rem;
}
  .submit {
    flex-direction: column;
  }
  .contact-form {
    height: 70vh;
    width: 90vw;
    margin: auto;
  }
  .about-me {
    width: 90vw;
    font-size: 1.5rem;
  }
  .web-flex {
    gap: 3rem;
  }
  .form-h2 {
    position: absolute;
    left: 0;
    width: 100%;
  }
  h2 {
    font-size: 2.4rem;
  }
  .project-img{
    width: 70vw;
}
  .icon {
    height: 35px;
    width: 35px;
  }
  .project-heading {
    font-size: 2.5rem;
  }

  .underline {
    width: 53px;
  }
  .navbar-right.active {
    height: 70vh;
  }
  .navbar-right {
    display: none;
    height: 100vh;
    flex-direction: column;
    position: absolute;
    justify-content: space-evenly;
    top: 100%;
    right: 0;
    background-color: var(--background);
    width: 100vw;
    margin: 0;
    text-align: center;
    padding: 1rem;
    z-index: 99999999;
  }
  input[type="text"],
  input[type="email"],
  select,
  textarea {
    font-size: 1rem;
  }
}
@media screen and (min-width: 320px) and (max-width: 481px) {
  
.about-me{
    font-size: 1.4rem;
}
.links-1 a{
    font-size: 1.5rem;
}

h2{
    font-size: 3rem;
}
.img-div{
    height: 30%;
}
.contact-form{
    height: 70vh;
    font-size: 1rem;
}
h2{
    font-size: 2.5rem;
}
.icon-info{
    font-size: 1.3rem;
}
input[type="text"],
input[type="email"],
select,
textarea {
  font-size: .9rem;
}
.project-img{
    width: 60vw;
}
}
@media screen and (min-width: 1024px) and (max-width: 1342) {
.project-desc{
    font-size: 4.4rem;
}
}
/* Tablets (max-width: 768px) */
@media screen and (max-width: 768px) {
  #container {
    margin: 20px auto;
    width: 95%;
  }
}

/* Medium Devices (min-width: 768px) */
@media only screen and (min-width: 768px) and (max-width: 899px) {
  .main-heading-title {
    max-width: 50vw;
    font-size: 5rem;
  }
  .main-div {
    height: 80vh;
  }
  h1 {
    font-size: 2.3rem;
  }
  .img-div {
    height: 40%;
  }
  .about-me {
    width: 50vw;
    font-size: 1.5rem;
  }
  .projects-web {
    width: 90vw;
  }
  .card{
    display: none;
  }
  .about-me{
    text-align: center; 
    margin:auto
  }
  .project-img {
    padding: 10px;
  }
  .project-content {
    padding: 0;
  }
  .icon-info{
    font-size: 1.4rem;
    
    }
    h2{
        font-size: 2.5rem;
    }
  .contact-form {
    height: 50vh;
    width: 80vw;
  }
}

/* Large Devices (min-width: 992px) */
@media only screen and (min-width: 900px) and (max-width: 1023px) {
  .main-heading-title {
    max-width: 50vw;
    font-size: 7rem;
  }
  .project-desc{
    font-size: 1.7rem;
}
  /* .navbar {
    background-color: yellow;
  } */
  /* .img-div {
    height: 40%;
  } */
  .about-me {
    width: 50vw;
    font-size: 1.5rem;
  }
  .projects-web {
    width: 90vw;
  }
  .project-img {
    padding: 10px;
  }
  .project-content {
    padding: 0;
  }
  .img-div{
    height: 50%;
  }
  .contact-form {
    height: 100vh;
    width: 80vw;
  }
  .aboutme-content{
    gap: 0;
  }
  h2{
    font-size: 3rem;
  }
  .icon-info{
    font-size: 1.4rem;
}

  /* .main-div {
    height: 70vh;
  } */
}

/* Extra Large Devices (min-width: 1200px) */
@media only screen and (min-width: 1024px) and (max-width: 1280px) {
  .main-heading-title {
    max-width: 50vw;
    font-size: 7rem;
  }
  .project-desc{
    font-size: 4.4rem;
}
  .img-div {
    height: 40%;
  }
  h1{
    font-size: 3rem;
  }
  .about-me {
    width: 50vw;
    font-size: 1.7rem;
  }
  .projects-web {
    width: 90vw;
  }
  .icon-info{
    font-size: 1.2rem;
  }
  .project-img {
    padding: 10px;
  }
  .project-content {
    padding: 0;
  }
  h2{
    font-size: 3rem;
  }
  .contact-form {
    height: 50vh;
    width: 80vw;
  }
  .main-div {
    height: 100vh;
  }
  .project-desc{
    font-size: 1.4rem;
  }
}
@media only screen and (min-width: 1910px) {
    .project-desc{
        font-size: 2.4rem;
    }
    .main-heading-title{
        font-size: 16rem;
    }
    .button-21{
        font-size: 2rem;
    }
}
